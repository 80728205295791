import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { UserState } from '@store/user/types';
import { useSnackbar } from '@components/common/Snackbar';

const PrivateRoute: React.FC<{
	component: React.ElementType;
	[x: string]: any;
}> = ({ component: Component, ...props }) => {
	const location = useLocation();
	const [openSnackbar] = useSnackbar();
	// Extract user and website from Redux
	const { user } = useSelector(({ user }) => ({ user }));

	useEffect(() => {
		if (!user) {
			// user isn't logging out, notify them
			if (location.pathname !== '/logout') {
				openSnackbar('You need to be logged in to view that');
			}

			if (location.pathname !== '/login') {
				navigate('/login', { state: { from: location } });
			}

			return;
		}

		if (!user?.permissions?.isAdmin) {
			openSnackbar(`You don't have permission to view that.`);
			navigate('/dashboard', { state: { from: location } });
		}
	}, [user]);

	// Checks to see if the route is marked as admin only
	if (!user || !user?.permissions?.isAdmin) {
		// Return null to indicate to React/Gatsby we have nothing to render
		return null;
	}

	return <Component location={location} user={user} {...props} />;
};

export default PrivateRoute;
