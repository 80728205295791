import React, { useMemo } from 'react';
import { Router } from '@reach/router';
import { adminRoutes } from '@data/navigation';
import { Routes, Route, Navigation } from '@models/navigation';
import AdminRoute from '@components/Routes/AdminRoute';
import { cardColours, routeMatches } from '@helpers/common';
import { RoutesContext } from '@helpers/contexts';
import { getFlatRoutes, getRouteStack } from '@helpers/routes';
import Layout from '@components/common/Layouts/Dashboard';
import {
	AttachMoney,
	ContactMail,
	ContactPhone,
	CreditCard,
	Dashboard as DashboardIcon,
	Description,
	Help,
	People,
	Person,
	ShoppingCart,
	ViewList,
} from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Skeleton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const Dashboard = () => {
	const routes = adminRoutes();
	const flatRoutes = getFlatRoutes(routes);
	const routeStack = getRouteStack(routes);
	const currentRouteItem = routeStack?.[routeStack?.length - 1];
	const navigation: Navigation = [
		{
			items: [
				// {
				// 	name: 'Dashboard',
				// 	path: '/admin',
				// 	icon: <DashboardIcon />,
				// },
				{
					name: 'Cards',
					icon: <CreditCard />,
					children: [
						{
							name: 'Cards',
							path: '/admin/cards',
						},
						{
							name: 'Contact Cards',
							path: '/admin/contact-cards',
						},
						{
							name: 'Link Hubs',
							path: '/admin/link-hubs',
						},
						{
							name: 'External URLs',
							path: '/admin/external-urls',
						},
						{
							name: 'Forms',
							path: '/admin/forms',
						},
					],
				},
				{
					name: 'Users',
					icon: <Person />,
					children: [
						{
							name: 'Users',
							path: '/admin/users',
						},
						{
							name: 'Addresses',
							path: '/admin/addresses',
						},
						{
							name: 'Affiliates',
							path: '/admin/affiliates',
						},
					],
				},
				{
					name: 'Store',
					icon: <AttachMoney />,
					children: [
						{
							name: 'Orders',
							path: '/admin/orders',
						},
						{
							name: 'Checkouts',
							path: '/admin/checkouts',
						},
					],
				},
				{
					name: 'Help',
					icon: <Help />,
					children: [
						{
							name: 'Articles',
							path: '/admin/help/articles',
						},
						{
							name: 'Categories',
							path: '/admin/help/categories',
						},
					],
				},
			],
		},
	];

	return (
		<RoutesContext.Provider
			value={{
				routes,
				routeStack,
				currentRouteItem,
				flatRoutes,
				navigation,
			}}
		>
			<Layout>
				<Router>
					{flatRoutes?.map(({ path, component: Component, permissions }) => (
						<AdminRoute
							key={path}
							path={path}
							component={Component}
							permissions={permissions}
						/>
					))}

					{/*<Error404 default />*/}
				</Router>
			</Layout>
		</RoutesContext.Provider>
	);
};

export default Dashboard;
